@import '../components/colors.scss';


.ahmedrhuma-singlepreview {
    max-height: 400px;
    object-fit: cover;
}

.ahmedrhuma-drawer-contents {
    h1,h2 {
        color: $portfolio-primary;
    }
    p, ul {
        font-family: 'Roboto', sans-serif;
    }
    .icons-container {
        width: 100%;
        .ant-row {
            max-height: 100px;

            .ant-col {
                text-align: center;

                img {
                    width: 100%;
                    filter: grayscale(80%);
                    height: 100%;
                    max-height: 100px;
                }
            }
        }
    }
}