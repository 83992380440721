@import '../components/colors.scss';

.ahmedrhuma-resume-container {
    
    h1 {
        text-align: center;
        width: 100%;
        font-size: 4em;
        color: $primary;
        padding-left: 20px;
        font-weight: bolder;

        span {
            color: #fff;
        }
    }

    span.title-bg {
        font-size: 110px;
        letter-spacing: 10px;
        line-height: 0.7;
        position: absolute;
        right: 0;
        top: 49px;
        left: 32px;
        text-transform: uppercase;
        font-weight: 800;
        transform: translateY(-50%);
        color: rgba(255, 255, 255, 0.07);
    }

    .resume-rows {
        h2 {
            padding-bottom: 22px;
            font-size: 26px;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
        }

        p {
            color: #fff;
            font-size: 1.2em;
            font-family: 'Roboto', sans-serif;

            span {
                opacity: .8;
                text-transform: capitalize;
                font-family: 'Roboto', sans-serif;
            }
        }
    }

    .icons-container {
        img {
            width: 100%;
            filter: grayscale(80%);
            height: 100%;
            transition: all 0.5s ease-in-out;

            &:hover {
                filter: grayscale(0);
            }
        }

        .ant-col {
            text-align: center;
            border-right: 1px solid #96ad1163;
            padding: 0 20px !important;

            &:last-child {
                border-right: none;
            }
        }
    }

    .blocks-resume {
        .ant-col {
            margin-bottom: 30px;

            div {
                padding: 20px 30px 25px 40px;
                border-radius: 5px;
                border: 1px solid #d8bd144d;
                text-align: left;

                p {
                    font-family: 'Roboto', sans-serif;
                    padding-left: 45px;
                    position: relative;
                    font-size: 1.2em;
                    font-weight: 100;
                    text-transform: uppercase;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 13px;
                        width: 30px;
                        height: 1px;
                        background: #777;
                    }

                    span {
                        display: block;
                        font-size: 1.2em;
                        font-family: 'Yanone Kaffeesatz';
                        text-transform: uppercase;
                        font-weight: 100;
                    }
                }

                h3 {
                    position: relative;
                    display: inline-block;
                    margin: 0;
                    font-size: 50px;
                    font-weight: 700;
                    color: $primary;
                    font-family: 'Roboto', sans-serif;

                    &::after {
                        content: '+';
                        position: absolute;
                        right: -24px;
                        font-size: 33px;
                        font-weight: 300;
                        top: 2px;
                        font-family: 'Roboto', sans-serif;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px)  {
    .ahmedrhuma-resume-container {
        .icons-container {
            .ant-col {
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .ahmedrhuma-resume-container {
        font-size: 10px;

        h1 {
            font-size: 6em;
        }
        .ahmedrhuma-showmore-container {
            .ahmedrhuma-showmore {
                min-width: 180px !important;
                padding: 16px 63px 16px 25px;
            }
        }
    }

    span.title-bg {
        font-size: 75px !important;
        top: 48px !important;
        left: 32px !important;
    }
}