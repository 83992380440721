.ahmedrhuma-custombg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    font-size: 0;
    pointer-events: none;
    z-index: 3;

    .ahmedrhuma-bgcontent {
        margin-left: -712px;
        position: absolute;
        left: 50%;
        top: 0;
        width: 1424px;

        .ahmedrhuma-lines {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 20%;
            height: 100vh;

            &::before {
                -o-transition-delay: 1.9s;
                -webkit-transition-delay: 1.9s;
                transition-delay: 1.9s;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 1px;
                height: 0;
                background: rgba(255,255,255,.1);
                transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
                -moz-transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
                -webkit-transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
                -o-transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
            }

            &::after {
                height: 0;
                top: 0;
                bottom: auto;
                -webkit-transition-delay: .7s;
                -o-transition-delay: .7s;
                transition-delay: .7s;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: #1e1e1e;
                z-index: 12;
                transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
                -moz-transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
                -webkit-transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
                -o-transition: height .65s cubic-bezier(.165,.85,.45,1) 0s;
            }

            &:last-child {
                &::after {
                    left: 0;
                    right: auto;
                    width: 1000%;
                }
            }

            &:first-child {
                &::before {
                    background: 0 0;
                }

                &::after {
                    left: auto;
                    right: 0;
                    width: 1000%;
                }
            }

            &:nth-child(2) {
                &::before {
                    -o-transition-delay: 1.7s;
                    -webkit-transition-delay: 1.7s;
                    transition-delay: 1.7s;
                }

                &::after {
                    top: 0;
                    bottom: auto;
                    -webkit-transition-delay: .5s;
                    -o-transition-delay: .5s;
                    transition-delay: .5s;
                }
            }

            &:nth-child(3) {
                &::before {
                    -o-transition-delay: 1.2s;
                    -webkit-transition-delay: 1.2s;
                    transition-delay: 1.2s;
                }

                &::after {
                    top: 0;
                    bottom: auto;
                    -webkit-transition-delay: 0s;
                    -o-transition-delay: 0s;
                    transition-delay: 0s;
                }
            }

            &:nth-child(4) {
                &::before {
                    -o-transition-delay: 1.5s;
                    -webkit-transition-delay: 1.5s;
                    transition-delay: 1.5s;
                }

                &::after {
                    top: 0;
                    bottom: auto;
                    -webkit-transition-delay: .3s;
                    -o-transition-delay: .3s;
                    transition-delay: .3s;
                }
            }

            &:nth-child(5) {
                &::before {
                    -o-transition-delay: 2s;
                    -webkit-transition-delay: 2s;
                    transition-delay: 2s;
                }

                &::after {
                    top: 0;
                    bottom: auto;
                    -webkit-transition-delay: .8s;
                    -o-transition-delay: .8s;
                    transition-delay: .8s;
                }
            }
        }
    }

    &.ready {
        .ahmedrhuma-lines {
            &::before {
                background: #8bbc1008;
            }
        }
    }

    &.finish {
        .ahmedrhuma-lines {
            &::before {
                height: 100%;
            }

            &::after {
                height: 0;
            }
        }
    }
}

@media screen and (max-width:425px) {
    .ahmedrhuma-custombg {
        .ahmedrhuma-bgcontent {
            width: 600px;
            margin-left: -340px;
        }
    }
}

@media screen and (min-width: 1425px) {
    .ahmedrhuma-custombg {
        .ahmedrhuma-bgcontent {
            width: 3000px;
            margin-left: -1550px;
        }
    }
}