@import '~antd/dist/antd.dark.css';

body {
  background: #101010;
  overflow-x: hidden;
  font-family: 'Yanone Kaffeesatz', sans-serif;
}

h1,h2,h3,h4,h5, p, span {
  font-family: 'Yanone Kaffeesatz', sans-serif;
}

.ahmedrhuma-content {
  position: relative;
  z-index: 10;
  display: flex;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  overflow: hidden;
}

.ahmedrhuma-pages {
  width: 100%;
  padding: 10px 10px 10px 56px;
}

.ahmedrhuma-loadingcomponent {
  text-align: center;
  height: 100%;
  position: relative;
  top: 45%;
}

@media screen and (max-width: 768px) {
  body {
    overflow: auto;
  }
}