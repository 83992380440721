.ahmedrhuma-typer {
    color: #808080;
    min-height: 25px;

    .cursor {
        animation: blink 1s step-end infinite;
    }
}

@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
}