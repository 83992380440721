@import '../components/colors.scss';

.ahmedrhuma-main-title-container {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20%;

    .ahmedrhuma-showmore-container {
        width: 100%;
        text-align: center;

        .ahmedrhuma-showmore {
            color: #fff;
            border-color: $primary;
            background: $primary;
    
            overflow: hidden;
            display: inline-block;
            line-height: 1.4;
            border-radius: 35px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            cursor: pointer;
            vertical-align: middle;
            user-select: none;
            transition: 250ms all ease-in-out;
            text-transform: uppercase;
            text-decoration: none !important;
            position: relative;
            z-index: 1;
            padding: 16px 70px 16px 35px;
            font-size: 15px;
            font-weight: 600;
            background-color: transparent;
            outline: none !important;
            border: 1px solid $primary;
            min-width: 210px;
            height: 55px;

            &::after {
                background-color: $primary;
                z-index: -1;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transform: translateX(100%);
                transition: .3s ease-out all;
            }
    
            & > span:not(.anticon) {
                position: relative;
                z-index: 2;
                color: #fff;
                transition: .3s ease-out all;
                font-size: 1.5em;
                font-weight: 400;
                line-height: 1.2;
                padding-right: 9px;
            }
    
            & > span.anticon {
                position: absolute;
                right: -1px;
                top: -1px;
                bottom: 0;
                width: 55px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 19px;
                color: #ffffff;
                border-radius: 50%;
                background-color: $primary;
            }
    
            

            &:hover {
                &::after {
                    transform: translateX(0%);
                }
            }
        }
    }

    .maintitle {
        margin: 0 0 7px 33px;
        padding-right: 0;
        position: relative;
        left: 0;
        top: 0;
        bottom: auto;
        max-width: 800px;
        font-size: 44px;
        line-height: 1.3;
        font-family: "Roboto";
        font-weight: 500;
        text-transform: none;
        letter-spacing: 0em;
        font-family: 'Roboto', sans-serif;

        strong {
            color: $primary;
            font-family: 'Roboto', sans-serif;
        }
    }
    
    p {
        margin: 0 0 7px 33px;
        font-family: 'Roboto', sans-serif;
        strong {
            color: $primary;
            font-family: 'Roboto', sans-serif;
        }
    }
}