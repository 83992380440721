@import './colors.scss';

.ahmedrhuma-menu {
    width: 46px;
    position: fixed;
    left: 0;
    height: 100vh;
    background: #1e1e1e;

    .main-icon {
        background: $primary;
        padding: 15px;
        font-size: 1.2em;
        color: #000;
        transition: all .3s ease-in-out;
        cursor: pointer;

        &:hover {
            background: $primary;
        }
    }

    .ahmedrhruma-nav {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: 0;
        
        nav {
            text-align: center;

            div {
                margin: 20px 0;
                a {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 1.8em;
                    color: #6b6b6b;
        
                    &:last-child {
                        margin-bottom: 0;
                    }
    
                    &.active {
                        color: $primary;
                    }
                }
            }
        }
    }

}

.page-portfolio {
    .ahmedrhuma-menu {
        .main-icon {
            background: $portfolio-primary;
        }

        .ahmedrhruma-nav {
            nav {
                div {
                    a {
                        &.active {
                            color: $portfolio-primary;
                        }
                    }
                }
            }
        }
    }
}

.page-contact {
    .ahmedrhuma-menu {
        .main-icon {
            background: $contact-primary;
        }

        .ahmedrhruma-nav {
            nav {
                div {
                    a {
                        &.active {
                            color: $contact-primary;
                        }
                    }
                }
            }
        }
    }
}