@import './colors.scss';

.ahmedrhuma-footer {
    padding: 0;
    position: fixed;
    bottom: 50px;
    left: 50px;
    right: 50px;
    width: auto;
    height: 1px;
    z-index: 99;

    .footer-arrow {
        width: 100%;
        text-align: center;
        font-size: 4em;
        position: relative;
        bottom: 40px;
        color: $primary;

        svg {
            cursor: pointer;
        }
    }

    .social {
        position: absolute;
        right: 0;
        bottom: 0;
        float: right;
        width: 24px;
        color: #bbb;
        z-index: 3;

        .follow {
            padding-right: 75px;
            position: absolute;
            bottom: 100%;
            right: 0;
            color: #bbb;
            font-size: 14px;
            line-height: 24px;
            height: 24px;
            white-space: nowrap;
            transform-origin: 100% 0;
            text-align: right;
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 50px;
                height: 1px;
                background: #fff;
            }
        }

        a {
            display: block;
            margin-bottom: 20px;
            text-align: center;
            color: #bbb;
            transition: all .7s cubic-bezier(.165,.85,.45,1);
            -moz-transition: all .7s cubic-bezier(.165,.85,.45,1);
            -webkit-transition: all .7s cubic-bezier(.165,.85,.45,1);
            -o-transition: all .7s cubic-bezier(.165,.85,.45,1);
            font-size: 1.3em;

            &:hover {
                color: $primary !important;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .ahmedrhuma-footer {
        left: 89px;
        .social {
            right: -25px;
        }
    }
}

@media screen and (max-width: 320px) {
    .ahmedrhuma-footer {
        .social {
            right: -50px;
        }
    }
}