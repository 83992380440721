@import '../components/colors.scss';

.ahmedrhuma-portfolio {
    h1 {
        text-align: left;
        font-size: 4em;
        color: $portfolio-primary;
        padding-left: 20px;
        margin: 0;
    }

    .ahmedrhuma-portfolio-note {
        text-align: center;
        //position: absolute;
        top: 10px;
        width: 100%;
        color: #9a7e7e;
    }

    .ahmedrhuma-tabs-content {
        //position: absolute;
        width: 100%;
        min-height: 100vh;
        top: 5%;

        .ant-card-cover {
            max-height: 270px;

            img {
                max-height: 270px;
                height: 270px;
                object-fit: cover;
            }
        }

        .ant-tabs-content-holder {
            overflow-y: auto;
            //position: absolute;
            height: 100%;
            width: 100%;
            top: 72px;
            padding-bottom: 150px;

            .ant-card-meta-title {
                font-size: 2em;
                color: $portfolio-primary;
            }

            .ant-card-meta-description {
                font-family: 'Roboto', Arial;
                min-height: 88px;
            }

            .icons-container {
                img {
                    width: 100%;
                    filter: grayscale(80%);
                    height: 91px;
                    transition: all 0.4s ease-in-out;

                    &:hover {
                        filter: grayscale(0);
                    }
                }
            }

            .ant-tabs-tabpane {
                padding: 0 50px;
            }

            .item-col {
                margin-bottom: 20px;
            }

            .ant-empty {
                margin: 0 auto;
            }
        }

        .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
            padding: 16px;
        }
    
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $portfolio-primary;
        }
    
        .ant-tabs-ink-bar {
            background: $portfolio-primary;
        }
    
        .ant-tabs-tab {
            font-size: 1.5em;
    
            .ant-tabs-tab-btn {
                color: #909090;
                font-size: 1.2em;
    
                &.ant-tabs-tab-active {
                    color: $portfolio-primary;
                }
    
                & > span {
                    & > span {
                        color: $portfolio-primary;
                    }
                }
            }
        }
    }


}

@media screen and (max-width: 425px) {
    .ahmedrhuma-portfolio h1 {
        margin: 16px 0 0 0;
        text-align: center;
        padding: 0;
    }

    .ahmedrhuma-portfolio .ahmedrhuma-tabs-content .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
        padding: 6px;
        margin: 0;
        font-size: 14px;
    }
}

@media screen and (max-width: 424px) {
   
    .ahmedrhuma-portfolio .ahmedrhuma-tabs-content .ant-card-cover img {
        height: 150px;
    }

    .ahmedrhuma-portfolio .ahmedrhuma-tabs-content .ant-tabs-content-holder .icons-container img {
        height: 60px;
    }
}