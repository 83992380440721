@import '../components/colors.scss';

.ahmedrhuma-contact {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;

    span.title-bg {
        font-size: 110px;
        letter-spacing: 10px;
        line-height: 0.7;
        position: absolute;
        right: 0;
        top: 10px;
        left: -36px;
        text-transform: uppercase;
        font-weight: 800;
        transform: translateY(-50%);
        color: rgba(255, 255, 255, 0.07);
    }

    .ant-btn-primary, .ant-btn-primary:focus,.ant-btn-primary:active {
        color: #fff;
        border-color: $contact-primary;
        background: $contact-primary;

        overflow: hidden;
        display: inline-block;
        line-height: 1.4;
        border-radius: 35px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
        user-select: none;
        transition: 250ms all ease-in-out;
        text-transform: uppercase;
        text-decoration: none !important;
        position: relative;
        z-index: 1;
        padding: 16px 70px 16px 35px;
        font-size: 15px;
        font-weight: 600;
        background-color: transparent;
        outline: none !important;
        border: 1px solid $contact-primary;
        min-width: 210px;
        height: 55px;

        & > span:not(.anticon) {
            position: relative;
            z-index: 2;
            color: #fff;
            transition: .3s ease-out all;
            font-size: 1.5em;
            font-weight: 400;
            line-height: 1.2;
            padding-right: 9px;
        }

        & > span.anticon {
            position: absolute;
            right: -1px;
            top: -1px;
            bottom: 0;
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 19px;
            color: #ffffff;
            border-radius: 50%;
            background-color: $contact-primary;
        }

        &::before {
            background-color: $contact-primary;
            z-index: -1;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateX(100%);
            transition: .3s ease-out all;
        }
    }

    .ahmedrhuma-formnote {
        font-size: 1.2em;
        color: #7b7b7b;
    }

    .ahmedrhuma-leftarea {
        h2 {
            font-size: 2.5em;
        }

        p {
            font-size: 1.25em;
            color: #7b7b7b;
        }

        .ahmedrhuma-email {
            line-height: 2;
        }

        .ahmedrhuma-emailicon {
            text-align: center;

            .anticon {
                font-size: 2em;
                color: $contact-primary;
            }
        }
    }


    h1 {
        text-align: left;
        font-size: 4em;
        color: $contact-primary;
        padding-left: 20px;
        font-weight: bolder;

        span {
            color: #fff;
        }
    }

    .ant-form-item-control-input-content {
        input {
            height: 45px;
            border-radius: 20px;
        }

        textarea {
            border-radius: 20px;
        }
    }

    .ahmedrhuma-contactresult {
        .anticon {
            color: $contact-primary !important;
        }
    }

    
}

.rc-anchor-container {
    background: transparent;
    border-color: $contact-primary;
}

.rc-anchor-light {
    background: transparent;
}

.rc-anchor-light.rc-anchor-normal {
    border-color: $contact-primary !important;
}

@media screen and (min-width: 2200px) {
    .ahmedrhuma-contact {
        max-width: 2000px;
    }
}

@media screen and (max-width: 768px) {
    .ahmedrhuma-contact {
        h1 {
            text-align: center;
        }

        .title-bg {
            text-align: center;
            left: 48px !important;
        }
    }
}

@media screen and (max-width: 425px) {
    .ahmedrhuma-contact {
        .title-bg {
            left: 8px !important;
        }
    }
}


@media screen and (max-width: 375px) {
    .page-contact {
        .ahmedrhuma-pages {
            padding: 10px 10px 10px 58px !important;

            .ahmedrhuma-contact {
                .title-bg {
                    font-size: 85px !important;
                    top: 17px !important;
                    left: 17px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 321px) {
    .page-contact {
        .ahmedrhuma-pages {
            .ahmedrhuma-contact {
                .title-bg {
                    font-size: 75px !important;
                    left: 3px !important;
                    top: 20px !important;
                }
            }
        }
    }
}


@media screen and (min-width: 1024px) {
    .ahmedrhuma-contact {
        .title-bg {
            left: 0 !important;
        }
    }
}